<template>
  <div class="donation-area">
    <div class="header">
      <h4>{{ phase1 ? $t('Success.manageYourTickets') : $t('Success.saveYourTickets') }}!</h4>
    </div>
    <div class="details">
      <p>
        {{
          phase1 && displayBecomeAPlayer
            ? $t('Success.manageYourRafflesMessage')
            : $t('Success.downloadTicketsAltMessage')
        }}
      </p>
      <div class="downloadTicketsContainer">
        <div class="player-or-download">
          <b-button v-if="phase1 && displayBecomeAPlayer" variant="success" @click="redirectToPlayerPortal">
            <span>{{ $t('Success.becomePlayer') }}</span>
          </b-button>
          <DownloadTicketsButton />
        </div>
      </div>
      <ErrorHandler v-if="errorMessage" :error="errorMessage" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import DownloadTicketsButton from '@/components/DownloadTicketsButton.vue';
import { initializeUnleash, unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import config from '@/config';

export default Vue.extend({
  name: 'JoinPlayerPortal',
  components: {
    DownloadTicketsButton
  },
  data() {
    return {
      phase1: false,
      errorMessage: undefined as string | undefined,
      loggedIn: false
    };
  },
  computed: {
    displayBecomeAPlayer() {
      return !this.$store.getters.player;
    }
  },
  mounted() {
    // Initialize Unleash and check for phase1 feature flag
    initializeUnleash().then(() => {
      this.phase1 = unleashFeatureEnabled(UnleashKeys.Phase1);
    });
  },
  methods: {
    redirectToPlayerPortal() {
      const parentRaffleOrderId = this.$route.params.orders
        ? JSON.parse(this.$route.params.orders)[0]?.orderIdLong
        : null;
      const baseUrl = config.PLAYER_PORTAL_URL;

      const customer = this.$store.getters.customer;

      const queryString = new URLSearchParams(customer).toString();

      // Redirect to player portal with customer data
      window.open(`${baseUrl}?orderId=${parentRaffleOrderId}&${queryString}`);
    }
  }
});
</script>

<style lang="scss" scoped>
.donation-area {
  margin-bottom: 1rem;
  border: 1px solid #787878;
  border-radius: 11px;
}
.header {
  padding: 1rem 1rem 0.5rem 1rem;
  background-color: #15803d;
  color: #ffffff;
  border-bottom: 1px solid #787878;
  border-radius: 10px 10px 0 0;
  font-family: $heading;
  h4 {
    font-size: 32px;
  }
}
.details {
  padding: 1rem 1rem;
  font-size: 20px;
  .player-or-download {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 2rem;
    p {
      margin-bottom: 1rem;
    }
  }
}
</style>
